.container {
   width: 100vw;
   height: 100vh;
   background-color: #ff7f50;
   color: white;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

h1,
p {
   margin: 0;
   padding: 0;
}
